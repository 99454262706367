import { Link } from 'react-router-dom';
import CaseStudyHeader from "../components/CaseStudy/Header";
import CaseStudyIntro from "../components/CaseStudy/Intro";
import CaseStudyProject from "../components/CaseStudy/Project";
import CaseStudySample from "../components/CaseStudy/Sample";
import dashboard from '../images/dashboard.svg';

import ActDisplayThree from '../images/projects/act-ipad-display3.png';
import ActDisplayHand from '../images/projects/act-ipad-displayhand.png';

const introTitle = "ACT iPad Donation App";

const caseStudy = {
    studySubtitle: "Donation application on the iPad for use at private donor events",
    studyIcon: dashboard,
    clientDesc: "American Conservatory Theater",
    developerDesc: "American Conservatory Theater"
}

const caseStudyIntro = {
    aboutCopy: ['ACT (American Conservatory Theater) is a non-profit theater company and professional school based in San Francisco. It has over 65 years of history in the city as a leading provider of world-class original plays and is, as stated on their ', <Link to="http://www.act-sf.org/">website</Link>, ", ", ' "the first actor training program in the United States not affiliated with a college or university accredited to award a master of fine arts degree."'],
    problemCopy: "The project was created from a need for an alternative way to complete donor forms during salon events. During these events, which usually were held witin donor's homes, the tone of the evening was usually more intimate and relaxed while guests learned about the theater and the new season. Because of this, presenting paper forms to the guests afterwards felt very contractual and not like they were sponsoring an exciting production. Additionally, bringing stacks of forms to each event was cumbersome, not ennvironmentally-friendly and wasn't in line with what incoming donors were expecting."
}

const caseStudyProject = {
    goalsCopy: ["The goal of this project was to create a donation application that:"],
    goalsId:[0,1,2,3,4,5],
    goalsList: ["must only use subcriptions and technologies already paid for by ACT as a 'no-additional-cost' product.", "allows donors to provide billing information that can be easily processed after the event.", "is more enjoyable for donors to use in a contemporary format.", "can be easily used one handed while carrying a glass of wine or carrying a clutch under the arm.", "won't allow users to accidentally delete payment data.", "can switch quickly between pages and allow the Development team to maneuver the screen easily while it is facing the donor."],
    roleCopy: "Development Coordinator scoping the project, designing the application, developing the product, and training staff in how to use the application.",
    listPlayers: [
      ["Donors ", "These are the ones who will be filling out the donation with their information. Because they would be completing this information at an event, they may be preoccupied with socializing, carrying wine glasses or plates of food, or have difficulties with mobility. Additionally, they usually are hesitant to make a pledge if the process is too complicated and lengthy. They want to know that they are supporting things that align with their values and looks good. They like seeing new things though they themselves may be a bit slower at adapting to its use."],
      ["Development Team ", "This is the team that will be collecting and processing the donor information. They will be helping to walk through the productions that are available to sponsor and also may assist in filling out the form for donors with low mobility. They also will be processing the billing information and reconnecting with interested donors after the salon event."]
    ]
}

const projectImages = [
  { pic: ActDisplayHand, alt: "view of iPad in hand showing a sponsoring pledge form.", desc: "Display of ACT donation application on iPad in hand." },
  { pic: ActDisplayThree, alt: "view of three iPad screens each showing different pledge forms in full screen.", desc: "Display of three different views of the ACT donation application on the iPad."}
]

function ActIpad () {
  return(
    <>
      <CaseStudyHeader studyIcon={caseStudy.studyIcon} introTitle={introTitle} studySubtitle={caseStudy.studySubtitle} clientDesc={caseStudy.clientDesc} developerDesc={caseStudy.developerDesc} />
      <CaseStudyIntro introTitle={introTitle} aboutCopy={caseStudyIntro.aboutCopy} problemCopy={caseStudyIntro.problemCopy} />
      <CaseStudyProject introTitle={introTitle} goalsCopy={caseStudyProject.goalsCopy} goalsId={caseStudyProject.goalsId} listGoals={caseStudyProject.goalsList} roleCopy={caseStudyProject.roleCopy} listPlayers={caseStudyProject.listPlayers} />
      <CaseStudySample introTitle={introTitle} projectImages={projectImages} />
    </>
  );
}

export default ActIpad;
