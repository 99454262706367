import CaseStudyHeader from "../components/CaseStudy/Header";
import CaseStudyIntro from "../components/CaseStudy/Intro";
import CaseStudyProject from "../components/CaseStudy/Project";
import CaseStudySample from "../components/CaseStudy/Sample";
import bird from '../images/bird.svg';

import CameraControl from '../images/projects/naturecam_cameracontrol.png';
import CameraDashboard from '../images/projects/naturecam_cameradashboard.png';
import CameraSnapshot from '../images/projects/naturecam_snapshots.png';
import TakeSnapshot from '../images/projects/naturecam_takesnapshot.png';
import VideoSeg from '../images/projects/naturecam_videoseg.png';

const introTitle = "NatureCam Camera Controls";

const caseStudy = {
    studySubtitle: "Camera controls for camera operators of leading nature live camera service",
    studyIcon: bird,
    clientDesc: "Explore.org",
    developerDesc: "Ministry of Velocity"
}

const caseStudyIntro = {
    aboutCopy: "This nature live camera service is one of the leading providers of footage from wildlife reserves and animal rescues. Their mission is to provide hours of educational footage and encourage the world to be passionate about the nature and the world we live in. They partner with many non-profits around the world to provide them a major platform to get the word out about their specific causes. Many of the feeds on this site are managed by a passionate mix of voluteer and paid camera operators who are driven to get the best shots for their audiences.",
    problemCopy: "This project focused on replacing the existing camera control interface for live cam operators and building a more sleeker and faster application in its place. Other elements of this project involved a revitalization of the snapshot feature, streaming to Facebook Live and YouTube Live, as well as making the process of archiving and creating segments from hundreds of live data easy to manage."
}

const caseStudyProject = {
    goalsCopy: ["The goal of this project was to create a new camera control system that:"],
    goalsId:[0,1,2,3],
    goalsList: ["is flexible to accomodate the organization's major camera brands.", "makes it easier for camera operators to make reliable camera moves without error.", "has the ability to create and manage snapshots, push camera footage to social media live accounts, and to be able to quickly pull camera segments from hundreds of hours of footage for research.", "has a secure user management built in."],
    roleCopy: "Software engineer pairing with other engineers from Ministry of Velocity and providing insight between engineering and design teams.",
    listPlayers: [
      ["Camera Operators ", "These are the people who are volunteering or hired by their organizations to monitor live cams and make moves in shifts. The focus of their job is to make sure the cameras show interesting and educational moments that happen in their environment."],
      ["Admins ", "Employees of the nature camera organization that have the ability to access all content on the application. They are able to provide and remove access to partnered organizations. These admins have to look at the entire scope of video feeds on the entire application and be able to quickly locate feed interruptions. Being able to archive large amounts of video for scientific research is also a concern for them."]
    ]
}

const projectImages = [
  { pic: CameraControl, alt: "view of nature cam's camera control interface showing the controls over a video of ducks in a lake.", desc: "View of NatureCam's camera control interface." },
  { pic: TakeSnapshot, alt: "view of a selected screenshot with a selection of available screenshots below.", desc: "View while taking and saving a snapshot within the camera control interface."},
  { pic: CameraDashboard, alt: "view of interface listing cameras available, each showing their status and if they are currently live.", desc: "The list view of cameras within NatureCam." },
  { pic: CameraSnapshot, alt: "view of the snapshot gallery with the highlighted selection in main view and the rest of the available gallery below.", desc: "The Snapshot dashboard view where camera operators can name saved footage for archiving purposes." },
  { pic: VideoSeg, alt: "view of the selected video snapshot segment showing a video of ducks and available gallery below the video.", desc: "The Snapshot dashboard view where camera operators can view, cut segments, and download saved snapshots." }
]

function NatureCam () {
  return(
    <>
      <CaseStudyHeader studyIcon={caseStudy.studyIcon} introTitle={introTitle} studySubtitle={caseStudy.studySubtitle} clientDesc={caseStudy.clientDesc} developerDesc={caseStudy.developerDesc} />
      <CaseStudyIntro introTitle={introTitle} aboutCopy={caseStudyIntro.aboutCopy} problemCopy={caseStudyIntro.problemCopy} />
      <CaseStudyProject introTitle={introTitle} goalsCopy={caseStudyProject.goalsCopy} goalsId={caseStudyProject.goalsId} listGoals={caseStudyProject.goalsList} roleCopy={caseStudyProject.roleCopy} listPlayers={caseStudyProject.listPlayers} />
      <CaseStudySample introTitle={introTitle} projectImages={projectImages} />
    </>
  );
}

export default NatureCam;
