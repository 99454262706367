import { HashLink } from 'react-router-hash-link';
import './../../Main.scss';
import MyButton from './../../components/MyButton';
import heroArt from './../../images/plant_hero_image.png';

const heroImage = {
  name: 'AI generated image of plants with butterflies',
  imageUrl: heroArt,
};

export default function Hero() {

  return (
    <section className="flex flex-col lg:flex-row">
      <section className="lg:hidden lg:w-6/12 flex justify-center">
      {/*Mobile Hero Image*/}
        <img src={heroImage.imageUrl} alt={heroImage.name} className="sm:max-w-lg pt-8"/>
      </section>
      <section className="lg:w-6/12 flex flex-col m-auto justify-center">
        <article className="pt-12 text-center lg:text-left" >
          <article className="text-5xl main__header max-w-md lg:max-w-lg">Front-End Engineer & Visual Designer</article>
          <article className="my-4 text-xl max-w-md lg:max-w-full text-amber-900">I make accessible websites that are beautiful and fun. 💖 </article>
        </article>
        <article className="sm:mt-2 mt-6 mb-6 flex justify-center lg:justify-normal">
          <HashLink smooth to="/#portfolio">
            <MyButton
              buttonTitle= "My Work"
            />
          </HashLink>
        </article>
      </section>
      <section className="max-lg:hidden w-6/12 flex justify-center">
      {/*Desktop Hero Image*/}
        <img src={heroImage.imageUrl} alt={heroImage.name} className="main__hero-image"/>
      </section>
    </section>
  );
}

