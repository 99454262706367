import '../../../Main.scss';

function CaseStudyHeader ({ studyIcon, introTitle, studySubtitle, clientDesc, developerDesc }) {

  return (
    <>
      <section className="flex flex-col text-center my-20">
        <img src={studyIcon} alt="bird icon" className="w-10 m-auto"/>
        <h1 className="text-5xl main__header mt-8 sm:mb-2 mb-4 italic">{introTitle}</h1>
        <h2 className="mb-8">{studySubtitle}</h2>
        <h3 className="px-5 py-2.5 w-32 border-2 border-black m-auto">Case Study</h3>
      </section>
      <section className="flex justify-between sm:px-12 pb-2 pt-10">
        <p><span className="main__casestudy font-bold">Client: </span><br className="sm:hidden"/>{clientDesc}</p>
        <p><span className="main__casestudy font-bold">Developed Under: </span><br className="sm:hidden"/>{developerDesc}</p>
      </section>
      <hr className="border border-black"/>
    </>
    );
}

export default CaseStudyHeader;
