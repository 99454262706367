import { Link } from 'react-router-dom';
import CaseStudyHeader from "../components/CaseStudy/Header";
import CaseStudyIntro from "../components/CaseStudy/Intro";
import CaseStudyProject from "../components/CaseStudy/Project";
import ecommerce from '../images/produce.svg';

const introTitle = "CAFF RFP Bid Generator";

const caseStudy = {
    studySubtitle: "RFP document creation app for non-profit food procurement from local farmers for school lunches",
    studyIcon: ecommerce,
    clientDesc: "Community Alliance with Family Farmers (CAFF)",
    developerDesc: "Ministry of Velocity"
}

const caseStudyIntro = {
    aboutCopy: [<Link to="https://caff.org/">Community Alliance with Family Farmers (CAFF)</Link>, ' is a California nonprofit that promotes sustainable food systems through policy advocacy and practical programs. They do this work by supporting family farmers and serving diverse community members, including consumers, food service directors, schoolchildren, and low-income populations, to build a more resilient, just, and abundant food system for all Californians.'],
    problemCopy: "CAFF was looking for a way to streamline the RFP creation process for farmers who participated in their farm-to-school-lunches program. They had been spending a great deal of resources assisting farmers through a long and complicated application process that involved various different programs that didn't quite fit their needs. This RFP creation app was one of their solutions to making creating and managing RFPs for farmers and CAFF staff much easier."
}

const caseStudyProject = {
    goalsCopy: ["The goal of this project was to create an RFP management application that:"],
    goalsId:[0,1,2,3],
    goalsList: ["is intuitive and easy to follow the steps to completion.", "uses a series of project-specific questionaires that results in a cohesive Microsof Word document that can be submitted for bid applications.", "allows for CAFF admins to be able to complete the review process of documents from an admin backend and be able to make changes", "sends RFP creation updates to farmers to their email addresses to keep them notified of their steps in the approval process."],
    roleCopy: "Software Engineer pair coding with Project Lead. My capacity in this project was as a Front End specialist and adding insight to visual design problems.",
    listPlayers: [
      ["Farmers ", "These are the customers of the app. They posess a wide range of experience with technology and need to manage various different aspects of their businesses so creating an application ther doesn't rely on them learning an entire new process was key. They may be applying on many different platforms at any given time so any hurdles can mean CAFF loses out on a potential supplier."],
      ["School Administrators ", "These are the representatives within a given school district. Because each school district has many different approval processes and can range from having one approver to a series of individuals in the process, the RFPs that are created within this app need to be able to fuction with various different regulations."],
      ["CAFF Admins ", "Staff within the CAFF organization. They are the individuals that will be managing the SOQs that are posted on the application and working with the Farmers through an approval process while they are submitting RFPs. They will be need a backend that allows them to easily coordinate with Farmers and School Administrators."]
    ]
}

function Caff () {
  return(
    <>
      <CaseStudyHeader studyIcon={caseStudy.studyIcon} introTitle={introTitle} studySubtitle={caseStudy.studySubtitle} clientDesc={caseStudy.clientDesc} developerDesc={caseStudy.developerDesc} />
      <CaseStudyIntro introTitle={introTitle} aboutCopy={caseStudyIntro.aboutCopy} problemCopy={caseStudyIntro.problemCopy} />
      <p class="text-center pt-8">Click <Link to="https://github.com/CAFF-F2C/bid-generator">here</Link> to view the open source project repo in Github.</p>
      <CaseStudyProject introTitle={introTitle} goalsCopy={caseStudyProject.goalsCopy} goalsId={caseStudyProject.goalsId} listGoals={caseStudyProject.goalsList} roleCopy={caseStudyProject.roleCopy} listPlayers={caseStudyProject.listPlayers} />
    </>
  );
}

export default Caff;
