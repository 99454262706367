function ProjectButton ({ id, buttonTitle, buttonLink, buttonImage, buttonAlt, buttonDesc}) {
  return (
    <article className="flex flex-col lg:flex-row">
      <a href={ buttonLink }>
        <button id={ id } className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 bg-orange-300 hover:bg-orange-200 sm:w-96 sm:h-80 text-amber-900 font-bold rounded-lg overflow-hidden">
          <img src={ buttonImage } alt={ buttonAlt } className=""/>
        </button>
      </a>
      <article className="w-80 h-full mb-8 mt-2 lg:px-8 py-4 main__background">
        <h2 className="sm:text-3xl text-2xl main__subheader text-amber-900 pb-2"> { buttonTitle } </h2>
        <p> { buttonDesc } </p>
        <a href={ buttonLink }>> View Case Study</a>
      </article>
    </article>
  );
}

export default ProjectButton;
