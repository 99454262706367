import '../../Main.scss';
import { Link } from 'react-router-dom';

export default function PageLinks() {

  return(
    <section className="text-center mt-28">
      <h1 className="text-5xl mb-8 main__subheader text-amber-900">🔗 Links 🔗</h1>
      <article className="grid grid-rows-2 grid-flow-col gap-16 place-content-center">
        <Link to="">Resume</Link>
        <Link to="">Github</Link>
        <Link to="">LinkedIn</Link>
        <Link to="">Blog</Link>
      </article>
    </section>
  );
}
