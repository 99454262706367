import React from "react";
import Hero from '../components/Hero';
import Projects from '../components/Projects';
import Callback from '../components/Callback';

const Home = () => {
  return (
    <>
      <Hero />
      <Projects />
      <Callback />
    </>
  )
};

export default Home;
