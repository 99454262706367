import { Link } from 'react-router-dom';
import CaseStudyHeader from "../components/CaseStudy/Header";
import CaseStudyIntro from "../components/CaseStudy/Intro";
import CaseStudyProject from "../components/CaseStudy/Project";
import CaseStudySample from "../components/CaseStudy/Sample";
import ecommerce from '../images/ecommerce.svg';

import WaneloRewards from '../images/projects/wanelo_rewards_sample.png';
import WaneloEmails from '../images/projects/wanelo_rewards_emails.png';

const introTitle = "Wanelo Rewards Program UI";

const caseStudy = {
    studySubtitle: "Rewards program for a visual mobile and web eCommerce shopping marketplace",
    studyIcon: ecommerce,
    clientDesc: "Wanelo",
    developerDesc: "Ministry of Velocity"
}

const caseStudyIntro = {
    aboutCopy: ['Wanelo (from Want, Need, Love) is an online community and social platform for shopping. It was the first eCommerce site that focused on arranging products visually and making it a social media experience. The site is organized into two main sections: Trending products and My Feed. My Feed is personalized for each user and includes products and people they follow. Users can also save, comment on, and discuss products.', <p class="pt-4">Here are some articles about Wanelo:</p>, <p class="py-4"><Link to="https://www.forbes.com/sites/tomiogeron/2013/03/27/inside-wanelo-the-hot-social-shopping-service/">Forbes - "Inside Wanelo, The Hot Social Shopping Service"</Link></p>, <p><Link to="https://www.nytimes.com/2014/05/08/fashion/Wanelo-Social-Media-Shopping-Site-Deena-Varshavskaya-ecommerce.html">NYT - "Wanelo: A Social Media Shopping Site"</Link></p>],
    problemCopy: "The project was a new feature requested to create a new engaging experience for users. This was the first rewards program implemented to the site and the Wanelo team wanted the functionality to keep in kind with the visual focus of the application as a whole. As with all rewards programs, we focused on making it an interactive experience where shoppers would get to enjoy seeing higher savings rewards for continued loyalty to the app."
}

const caseStudyProject = {
    goalsCopy: ["The goal of this project was to create a rewards program interface that:"],
    goalsId:[0,1,2],
    goalsList: ["makes shoppers feel validated from their loyalty shopping with Wanelo.", "provides a beautiful platform where shoppers can keep track of their shopper status.", "integrates with the Wanelo’s email service so they can send credit reminders."],
    roleCopy: "Software Engineer pair coding with Project Lead. My capacity in this project was as a Front End specialist providing insight to translating designs into interactive UIs.",
    listPlayers: [
      ["Shoppers ", "These are the customers of the app. They are attracted to shopping on Wanelo for many reasons including the sites’ focus on visual aesthetics, ability to create collections of wish list items that they can share with friends, and the unique items sellers provide."],
      ["Sellers ", "Sellers can range from large stores to independent vendors that sell their goods on the site. They use Shopify as the backend for managing their inventory in coordination with the Wanelo specialized sellers’ backend platform."],
      ["Wanelo ", "Staff for Wanelo the company. They run moderation on the site managing the experiences of sellers and shoppers alike. Some of their processes include managing credits, sending promo emails, and disbursing funds for sales and refunds."]
    ]
}

const projectImages = [
  { pic: WaneloRewards, alt: "example of rewards screen for Wanelo showing platinum status rewards and credits", desc: "Example of the rewards UI for the mobile app. This shows the shopper's status, points earned, and rewards available for use." },
  { pic: WaneloEmails, alt: "two different screenshots of emails showing bright colored art and a message saying the user has credit to shop at Wanelo", desc: "Samples of the rewards emails that are issued to shoppers once they achieve milestones on the platform."}
]

function Wanelo () {
  return(
    <>
      <CaseStudyHeader studyIcon={caseStudy.studyIcon} introTitle={introTitle} studySubtitle={caseStudy.studySubtitle} clientDesc={caseStudy.clientDesc} developerDesc={caseStudy.developerDesc} />
      <CaseStudyIntro introTitle={introTitle} aboutCopy={caseStudyIntro.aboutCopy} problemCopy={caseStudyIntro.problemCopy} />
      <CaseStudyProject introTitle={introTitle} goalsCopy={caseStudyProject.goalsCopy} goalsId={caseStudyProject.goalsId} listGoals={caseStudyProject.goalsList} roleCopy={caseStudyProject.roleCopy} listPlayers={caseStudyProject.listPlayers} />
      <CaseStudySample introTitle={introTitle} projectImages={projectImages} />
    </>
  );
}

export default Wanelo;
