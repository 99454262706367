import '../../../Main.scss';

function CaseStudyIntro ({introTitle, aboutCopy, problemCopy}) {

  return (
    <section className="bg-white sm:px-12 px-6 py-4 my-10">
      <h1 className="main__casestudy font-bold">{introTitle} - Case Study</h1>
      <section className="flex flex-col lg:flex-row py-4 justify-between">
        <article className="lg:w-5/12 py-2">
          <h2 className="main__header">About</h2>
          <p>{aboutCopy}</p>
        </article>
        <article className="lg:w-5/12">
          <h2 className="main__header">Problem</h2>
          <p>{problemCopy}</p>
        </article>
      </section>
    </section>
  );
}

export default CaseStudyIntro;
