import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = () => {
  return (
    <main id="home" className="sm:px-16 py-16 px-12">
      <Navbar />
      <section className="body">
        <Outlet />
      </section>
      <Footer />
    </main>
  )
};

export default Layout;
