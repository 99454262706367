import '../../Main.scss';
import { Link } from 'react-router-dom';

export default function Contact({contactHeader}) {

  return(
    <section className="text-center mt-28">
      <h1 className="text-5xl mb-8 main__subheader text-amber-900">{contactHeader}</h1>
      <Link to="mail:contact@elovecmyk.com">contact@elovecmyk.com</Link>
    </section>
  );
}
