import '../../../Main.scss';

function CaseStudySample ({ introTitle, projectImages }) {

  const listSamples = projectImages.map(projectImage =>
    <>
      <img src={ projectImage.pic } alt={ projectImage.alt } />
      <p class='text-center pt-4 pb-20'>{ projectImage.desc }</p>
    </>
  );

  return (
    <section className="bg-white sm:px-12 px-6 pt-8 my-10">
      <h1 className="main__casestudy font-bold">{introTitle} - Case Study</h1>
      <section className="flex flex-col pt-8">
        {listSamples}
      </section>
    </section>
  );
}

export default CaseStudySample;
