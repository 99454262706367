import React from "react";
import '../Main.scss';
import PageTitle from "../components/Pages/pageTitle.jsx";
import ContactMe from "../components/Pages/contact.jsx";
import Links from "../components/Pages/pageLinks.jsx";

const pageTitle = {
  pageName: "Contact Me",
  pageSubtitle: "hey there! 👋🏾 let’s chat."
}

const contactHeader ={
  header: "💌 Email 💌"
}

function Contact () {
return (
  <>
    <PageTitle pageName={pageTitle.pageName} pageSubtitle={pageTitle.pageSubtitle} />
    <ContactMe contactHeader={contactHeader.header} />
    <Links />
  </>
);
};

export default Contact;
