import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './../../Main.scss';

function Navbar() {
  return (
    <section id='navigation' className='flex flex-col sm:flex-row items-center justify-between pb-10 text-amber-900'>
      <Link to="/" className='text-4xl sm:text-3xl main__header mb-6 hover:text-amber-900'>Erica Love</Link>
      <article className='flex flex-row space-x-12 text-lg'>
        <HashLink to="/#portfolio" className='hover:text-orange-400'>Portfolio</HashLink>
        <Link to="/about" className='hover:text-orange-400'>About</Link>
        <Link to="/contact" className='hover:text-orange-400'>Contact</Link>
      </article>
    </section>
  );
}

export default Navbar;
