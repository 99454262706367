import CaseStudyHeader from "../components/CaseStudy/Header";
import CaseStudyIntro from "../components/CaseStudy/Intro";
import CaseStudyProject from "../components/CaseStudy/Project";
import CaseStudySample from "../components/CaseStudy/Sample";
import dashboard from '../images/dashboard.svg';

import MobileLayoutOne from '../images/projects/formapp-mobile-layout-01.png';
import MobileLayoutTwo from '../images/projects/formapp-mobile-layout-02.png';

const introTitle = "FormApp Web Form Manager";

const caseStudy = {
    studySubtitle: "Form creation and management platform for site administrators and users",
    studyIcon: dashboard,
    clientDesc: "Ministry of Velocity",
    developerDesc: "Ministry of Velocity"
}

const caseStudyIntro = {
    aboutCopy: "This is an internally developed product designed and developed for the use of Ministry of Velocity and other clients.",
    problemCopy: "This project focused on building a platform that would easily create forms that could be placed within any website. These forms are able to be customized based on the needs of the admin and where the forms would be within a website. Its primary use would be for contact forms. This application also required an interface where the admin would be able to view and manage an inbox for each form that they have in use."
}

const caseStudyProject = {
    goalsCopy: ["The goal of this project was to create a form management application that:"],
    goalsId:[0,1,2,3],
    goalsList: ["allows admins to create custom forms that are easy to put on their sites.", "collects information and displays data in an inbox for each form.", "allows participants to be able to log in, find what their information is associated with in the application, and delete it.", "lets admins tailor submission alerts to the email addresses they need."],
    roleCopy: "Visual Designer and Software Engineer. My role involved creating the technical specification document, designing the application in Sketch, and development of the application.",
    listPlayers: [
      ["Web Admins ", "This is who will be creating an account to build custom forms. They care about making sure the forms will be getting only the information that they need for their organization and want the information submitted to be as high quality as possible. Form validation as well as the ability to get tailored alerts from each form is very valuable to them. Because there will potentially be a lot of data to process, having organized inboxes and ways to delete unecessary information is key."],
      ["Visitors ", "These are the individuals that are filling out the forms on live websites. Because they are looking to get in contact with the administrators of the websites they are visiting, they are hoping to put in as little personal information as possible to achieve the goal. Privacy features like having the ability to manage the information that they have submitted in the past is something that would be valuable."]
    ]
}

const projectImages = [
  { pic: MobileLayoutOne, alt: "showcase of three mobile views each showing the new form screen, form inbox, and message pages.", desc: "Mobile view of FormApp's form creation page and its form inbox functionality." },
  { pic: MobileLayoutTwo, alt: "showcase of two mobile screens each showing the forms view page and forms settings respectively.", desc: "View of FormApp's form overview list and form settings page."}
]

function FormApp () {
  return(
    <>
      <CaseStudyHeader studyIcon={caseStudy.studyIcon} introTitle={introTitle} studySubtitle={caseStudy.studySubtitle} clientDesc={caseStudy.clientDesc} developerDesc={caseStudy.developerDesc} />
      <CaseStudyIntro introTitle={introTitle} aboutCopy={caseStudyIntro.aboutCopy} problemCopy={caseStudyIntro.problemCopy} />
      <CaseStudyProject introTitle={introTitle} goalsCopy={caseStudyProject.goalsCopy} goalsId={caseStudyProject.goalsId} listGoals={caseStudyProject.goalsList} roleCopy={caseStudyProject.roleCopy} listPlayers={caseStudyProject.listPlayers} />
      <CaseStudySample introTitle={introTitle} projectImages={projectImages} />
    </>
  );
}

export default FormApp;
