import ProjectButton from './../ProjectButton';
import projectOne from './../../images/naturecam_promo_image.png';
import projectTwo from './../../images/wanelo_promo_image.png';
import projectThree from './../../images/caff_promo_image.png';
import projectFour from './../../images/actipad_promo_image.png';
import projectFive from './../../images/formapp_promo_image.png';

const projects = [
  { id: 0, buttonTitle: "NatureCam Camera Controls", buttonLink: "/nature-cam", buttonImage: projectOne, alt:"thumbnail showing laptop with camera control screen and video cartoon icons.", buttonDesc: "Camera controls for camera operators of leading nature live camera service"},
  { id: 1, buttonTitle: "Wanelo Rewards Program UI", buttonLink: "/wanelo", buttonImage: projectTwo, alt:"thumbnail showing the Wanelo homepage, example of rewards email, and phone displaying rewards screen.", buttonDesc: "Interactive rewards program applet for eCommerce website"},
  { id: 2, buttonTitle: "CAFF RFP Bid Generator", buttonLink: "/caff", buttonImage: projectThree, alt:"thumbnail ahowing three views of the bid generator screens and the CAFF logo.", buttonDesc: "RFP document creation app for non-profit food procurement from local farmers for school lunches"},
  { id: 3, buttonTitle: "ACT iPad Donation App", buttonLink: "/act-ipad", buttonImage: projectFour, alt:"thumbnail with iPad in hand with donation pledge screen displayed.", buttonDesc: "Donation application on the iPad for use at private donor events"},
  { id: 4, buttonTitle: "FormApp Web Form Manager", buttonLink: "/form-app", buttonImage: projectFive, alt:"thumbnail showing an iPad and a phone each showing the form management screens.", buttonDesc: "Form creation and management platform for site administrators and users"},
];

function MyProjects () {
  const listProjects = projects.map(project =>
    <ProjectButton key={ project.id } id={ project.id} buttonTitle={ project.buttonTitle } buttonLink={ project.buttonLink } buttonImage={ project.buttonImage } buttonAlt= { project.alt } buttonDesc={ project.buttonDesc } />
    );

  return (
    <section className="flex flex-col mt-24" id="portfolio">
      <article className="flex justify-center my-12 text-3xl main__subheader text-amber-900">Projects</article>
      <section className="m-auto space-y-6">{listProjects}</section>
    </section>
  );
}

export default MyProjects;

