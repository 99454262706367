import '../../../Main.scss';

function CaseStudyProject ({goalId, introTitle, goalsCopy, listGoals, roleCopy, listPlayers}) {

const playerList = listPlayers.map(listPlayer =>
    <p class="pb-4"><span className="font-bold">{listPlayer[0]} - </span>{listPlayer[1]}</p>);

const goalList = listGoals.map(listGoal =>
    <li key={goalId} id={goalId}>{listGoal}</li>);

  return (
    <section className="bg-white sm:px-12 px-6 py-8 my-10">
      <h1 className="main__casestudy font-bold">{introTitle} - Case Study</h1>
      <section className="flex flex-col lg:flex-row py-4 justify-between">
        <article className="lg:w-5/12 py-2">
          <h2 className="main__header">Project Goals</h2>
          <p>{goalsCopy}</p>
          <p className="pt-2">{goalList}</p>
          <h2 className="hidden lg:block pt-6 main__header">My Role</h2>
          <p className="hidden lg:block">{roleCopy}</p>
        <h2 className="lg:hidden lg:w-5/12 pt-6 main__header">My Role</h2>
        <p className="lg:hidden">{roleCopy}</p>
        </article>
        <article className="lg:w-5/12">
          <h2 className="main__header">Key Players</h2>
          <section>{playerList}</section>
        </article>
      </section>
    </section>
    );
}

export default CaseStudyProject;
