import React from "react";
import '../Main.scss';
import oops from "../images/oops.svg"

const NoPage = () => {
  return (
    <>
      <h1 className="text-center my-12 text-5xl main__header">Oops!</h1>
      <img src={oops} alt="cartoon of person with hands on head embarrassed and smiling." className="mb-12 w-20 m-auto" />
      <p className="text-center mb-2 lg:text-3xl text-2xl main__subheader text-amber-900">Hello, you might be at the wrong spot.</p>
      <p className="text-center lg:text-3xl text-2xl main__subheader text-amber-900">Try another page above or click 'Back' to go to your last page.</p>
    </>
  )
};

export default NoPage;
