import '../../Main.scss';

export default function PageTitle({pageName, pageSubtitle}) {

  return(
    <section className="text-center mt-28">
      <h1 className="text-5xl main__header">{pageName}</h1>
      <h2 className="mt-4 text-xl text-amber-900">{pageSubtitle}</h2>
    </section>
  );
}
