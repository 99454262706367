import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.scss';
import './App.scss';
import './Main.scss';
import Layout from './pages/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NatureCam from './pages/NatureCam';
import Wanelo from './pages/Wanelo';
import Caff from './pages/Caff';
import ActIpad from './pages/ActIpad';
import FormApp from './pages/FormApp';
import NoPage from './pages/NoPage';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path:"/portfolio",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/nature-cam",
        element: <NatureCam />,
      },
      {
        path: "/wanelo",
        element: <Wanelo />,
      },
      {
        path:"/caff",
        element: <Caff />,
      },
      {
        path:"/act-ipad",
        element: <ActIpad />,
      },
      {
        path: "/form-app",
        element: <FormApp />,
      },
      {
        path: "*",
        element: <NoPage />
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
