import React from "react";
import '../Main.scss';
import PageTitle from "../components/Pages/pageTitle.jsx";
import profilePhoto from "../images/linkedin_photo.jpg";
import ContactMe from "../components/Pages/contact.jsx";
import Links from "../components/Pages/pageLinks.jsx";

const pageTitle = {
  pageName: "Erica Love",
  pageSubtitle: "yup, that's my real name. ✨"
}

const contactHeader ={
  header: "Contact Me"
}

function About () {
return (
  <>
    <PageTitle pageName={pageTitle.pageName} pageSubtitle={pageTitle.pageSubtitle} />
    <img src={profilePhoto} alt="closeup of Erica in front of the Guggenheim Museum" className="size-72 mx-auto my-14 border-solid border-2 border-amber-800"/>
    <article className="max-w-screen-md text-center mx-auto space-y-4">
      <p><span className="text-2xl italic main__header">Hi! </span>I’m a software engineer and creative designer living in Oakland, California currently working at the San Francisco Foundation!!</p>
      <p>I’ve always been passionate about solving problems and making enjoyable digital experiences. I started as a freelance designer making designs for restaurants and non-profits. I then earned to code and got my first role developing applications for some really cool companies.</p>
      <p>I’m currently taking a break to follow my passion in the world of philanthropy but I’m always open to chat about how the internet can help people’s lives offline.</p>
    </article>
    <ContactMe contactHeader={contactHeader.header} />
    <Links />
    <section className="text-center mt-28">
      <h1 className="max-w-sm mx-auto mb-8 text-5xl main__subheader text-amber-900">When I have free time, I like to:</h1>
      <p className="max-w-md mx-auto">Grow organic produce, make linocut prints, study languages halfway, and make music with my daughter’s toy instruments.</p>
    </section>
    <section className="text-center mt-28">
      <h1 className="max-w-sm mx-auto mb-8 text-5xl main__subheader text-amber-900">Books</h1>
      <p className="max-w-md mb-4 mx-auto"><span className="text-amber-900 italic">How High We Go In The Dark,</span> Sequoia Nagamatsu</p>
      <p className="max-w-md mb-4 mx-auto"><span className="text-amber-900 italic">Scarlet Pimpernel,</span> Baroness Orczy</p>
      <p className="max-w-md mb-4 mx-auto"><span className="text-amber-900 italic">Sea of Tranquility,</span> Emily St. John Mandel</p>
      <p className="max-w-md mb-4 mx-auto"><span className="text-amber-900 italic">Spare,</span> Prince Harry</p>
    </section>
  </>
);
};

export default About;
