import { ReactComponent as Linkedin } from './../../images/linkedin.svg';
import { ReactComponent as Github } from './../../images/github.svg';
import { ReactComponent as Mail } from './../../images/mail.svg';

function Callback () {
  return(
    <section>
      <article className="mt-32 text-3xl main__subheader text-amber-900 flex justify-center text-center"> <span className="animate-spin-slow motion-reduce:animate-none px-4">🌸</span> Let's talk about apps. <span className="animate-spin-slow motion-reduce:animate-none px-4">🌸</span> </article>
      <section className="flex items-center justify-center py-12">
        <article className="main__social-media"><a href="https://www.linkedin.com/in/elovecmyk/"><Linkedin className="scale-150 fill-amber-900 hover:fill-orange-400"/></a></article>
        <article className="main__social-media px-20"><a href="https://github.com/ericalove"><Github className="scale-150 fill-amber-900 hover:fill-orange-400" /></a></article>
        <article className="main__social-media"><a href="/contact"><Mail className="scale-150 fill-amber-900 hover:fill-orange-400" /></a></article>
      </section>
    </section>
  );
}

export default Callback;
